//@flow
import * as React from 'react'
import styled from 'styled-components'
import LoginTemplate from '../../templates/LoginTemplate'

import { Link, withRouter } from 'react-router-dom'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'
import { Button } from 'sputnik-ui'
import { TextField, Typography } from '@material-ui/core'
import RegisterTemplate from '../../templates/RegisterTemplate'
import ConfirmPhoneModal from '../../molecules/ConfirmPhoneModal'

const LoginLink = styled(Link)`
  border: none;
  background: none;
  color: black;
  margin-top: 0.875rem;
  font-size: 0.875rem;
  cursor: pointer;
  &:hover {
    color: rgba(92, 92, 92, 1);
  }
  text-align: center;
  text-decoration: none;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* padding-top: 3.625rem; */
  min-width: 17rem;
  max-width: 30rem;
  height: 100%;
`
const StyledTextField = styled(TextField)`
    @media screen and (max-width: ${p => p.theme.sizes.phone}) {
      .MuiInput-underline:before {
    border-bottom: 1px solid #fff;
    }
    && .MuiInput-underline:hover:before {
      border-bottom: 1px solid #fff;
    }
    .MuiInput-underline:after {
      border-bottom: 2px solid #fff;
    }
    .MuiInput-formControl{
      color:#fff;
    }
    .MuiInputLabel-animated{
      color:#fff;

    }
    &&{
      label{
        color:#fff;
      }
    }
   }
`

const Form = styled.form`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 4rem;
  width: 100%;
  min-height: 22rem;
`

const H1 = styled.h1`
  font-weight: 200;
`

type Props = {
  classes: Object,
  dispatch: Function,
  user: ?Object,
  history: Object,
  match: Object,
}

type State = {
  name: string,
  surname: string,
  error: boolean,
  isConfirmed: boolean,
  nameError: string,
  surnameError: string,
  didRegisterSuccessfully: boolean,
}

class RegisterPage extends React.Component<Props, State> {
  state = {
    email: '',
    name: '',
    surname: '',
    phone: '',
    isConfirmed: false,
    error: false,
    nameError: '',
    surnameError: '',
    phoneError: '',
    didRegisterSuccessfully: false,
    confirmPhoneModal: false,
  }

  handleChange = prop => (e: SyntheticInputEvent<EventTarget>) => {
    this.setState({
      [prop]: e.target.value,
      error: false,
      [`${prop}Error`]: '',
    })
  }

  toggleConfirmPhoneModal = () =>
    this.setState({ confirmPhoneModal: !this.state.confirmPhoneModal })

  onSubmit = (e: SyntheticEvent<>) => {
    e.preventDefault()
    const { name, surname, phone } = this.state
    const {
      match: {
        params: { token },
      },
    } = this.props
    fetch(`${window.location.origin}/invites/users`, {
      method: 'POST',
      body: JSON.stringify({ name, surname, token, phone }),
      headers: { 'Content-Type': 'application/json' },
    })
      .then(r => {
        if (!r.ok) throw r
        return r.json()
      })
      .then(({ redirect_to, confirmation_required }) => {
        if (!confirmation_required) {
          window.location = redirect_to
          // this.props.history.push(`/welcome?redirect_to=${redirect_to || ''}`)
        } else {
          this.toggleConfirmPhoneModal()
        }
      })
      .catch(err => {
        err.json().then(response =>
          this.setState({
            emailError: response.message.email || '',
            nameError: response.message.name || '',
            surnameError: response.message.surname || '',
            phoneError: response.message.phone || '',
          }),
        )
      })
  }

  render() {
    return (
      <RegisterTemplate
        onSubmit={this.onSubmit}
        isConfirmed={this.state.isConfirmed}
        handleConfirmChange={e =>
          this.setState({ isConfirmed: !this.state.isConfirmed })
        }
        form={
          <>
            <StyledTextField
              required
              autoFocus
              autoComplete="name"
              onChange={this.handleChange('name')}
              label="Имя"
              error={!!this.state.nameError}
              margin="dense"
              helperText={this.state.nameError}
            />
            <StyledTextField
              required
              autoComplete="surname"
              onChange={this.handleChange('surname')}
              label="Фамилия"
              error={!!this.state.surnameError}
              margin="dense"
              helperText={this.state.surnameError}
            />

            <StyledTextField
              required
              autoComplete="phone"
              onChange={this.handleChange('phone')}
              label="Номер телефона"
              margin="dense"
              error={!!this.state.phoneError}
              helperText={this.state.phoneError}
            />
            <Button
              primary
              onClick={this.onSubmit}
              variant="v2"
              style={{ margin: '10px auto 0 auto ', width:320}}
            >
              Войти
            </Button>
          </>
        }
      >
        <ConfirmPhoneModal
          open={this.state.confirmPhoneModal}
          handleClose={this.toggleConfirmPhoneModal}
          phone={this.state.phone}
          history={this.props.history}
        />
      </RegisterTemplate>
    )
  }
}

export default withRouter(RegisterPage)
